import { Link } from "react-router-dom";
import '../CSS/Items.css'
import Carousel from "react-bootstrap/Carousel";

const Items = ({products, addToCart}) => 
{
    const productsMap=products.map((product, index) =>
    {
        let {id, name, image, category, price}=product
        return(
            <div key={id} className="item-card">
                <Carousel>
                    <Carousel.Item>
                        <img src={image} alt={name}/>
                    </Carousel.Item>
                </Carousel>
                <h5>{name}</h5>
                <p>Price: Kshs. {price.toLocaleString()}</p>
                <Link to={`/${name}`}>
                    <button className="btn btn-primary">Click to view</button>
                </Link>
                <button className="btn btn-success ms-5" onClick={()=>addToCart(name)}>Add to cart</button>
            </div>
        )
    })
    return ( 
        <>
            <div className="card-container">
                {productsMap}
            </div>
        </>
     );
}
 
export default Items;