
import Items from '../Items/Items'
import PlaceholderCard from '../Components/PlaceHolderCard'

const Shop = ({products, setProducts, addToCart}) => 
{
    
    return ( 
        <div className='main'>
            {
                products.length > 0 && 
                    <Items products={products} addToCart={addToCart}/>
            }
            {
                products.length === 0 &&
                    <div className="placeholder-container">
                        {
                            Array.from({ length: 10 }).map((_, index) => 
                                <PlaceholderCard key={index} />
                        )}
                    </div>
            }
        </div>
     );
}
 
export default Shop;