/* eslint-disable react-hooks/exhaustive-deps */
import { getAll } from "kenya-administrative-divisions";
import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
import '../CSS/Checkout.css';
import {toast} from 'react-toastify'

const Checkout = ({ cartItems, products, setCartCount }) => 
{
    const navigate=useNavigate()
    const [counties, setCounties] = useState([]);
    const [subCounties, setSubCounties] = useState([]);
    const [wards, setWards] = useState([]);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        county: "",
        sub_county: "",
        ward: "",
        street: "",
        amount: 0,
        products: []
    });

    useEffect(() => {
        getAll()
            .then(counties => {
                setCounties(counties.map(c => c));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleInputChange = e => setFormData({ ...formData, [e.target.id]: e.target.value });

    useEffect(() => {
        if (formData.county) {
            const matchingCounty = counties.find(county => county.county_name === formData.county);
            setSubCounties(matchingCounty ? matchingCounty.constituencies : []);
        } else {
            setSubCounties([]);
            setWards([]);
        }
    }, [formData.county, counties]);

    useEffect(() => {
        if (formData.sub_county) {
            const matchingSubCounty = subCounties.find(subCounty => subCounty.constituency_name === formData.sub_county);
            setWards(matchingSubCounty ? matchingSubCounty.wards : []);
        } else {
            setWards([]);
        }
    }, [formData.sub_county, subCounties]);

    useEffect(() => {
        let newTotalPrice = 0;
        let newProducts = [];
        cartItems.forEach((item, index) => {
            let product = products.find(p => p.name === item.name);
            if (product) {
                newTotalPrice += product.price * item.quantity;
                newProducts.push({ id: product.id, quantity: item.quantity }); // Add product id and quantity to newProducts array
            }
        });
        setFormData(prevFormData => ({
            ...prevFormData,
            amount: newTotalPrice,
            products: newProducts // Set the products array in formData to newProducts
        }));
    }, [cartItems, products]);

    const placeOrder = e => {
        e.preventDefault();
        // console.log(formData);

        fetch("https://api.litefluxent.com/client/orders",
            {
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            }
        )
        .then(response => response.json())
        .then(data => 
            {
            if (data.success) 
            {
                localStorage.removeItem("shopping-cart")
                setCartCount(0)
                navigate("/confirmation")
            } 
            else 
            {
                toast.error(data.error,
                    {
                        position: "top-right",
                        autoClose: 2000
                    }
                );
            }
        })
    };

    return (
        <>
            <div className="main d-flex mx-2 g-2">
                <div className="customer-details mx-2 p-2">
                    <h1 className="text-uppercase">Customer Details</h1>
                    <form onSubmit={placeOrder}>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label htmlFor="first_name" className="form-label">First Name</label>
                                <input type="text" className="form-control" id="first_name" placeholder="First Name" value={formData.first_name} onChange={handleInputChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="last_name" className="form-label">Last Name</label>
                                <input type="text" className="form-control" id="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleInputChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input type="text" className="form-control" id="email" placeholder="Email address" value={formData.email} onChange={handleInputChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phone" className="form-label">Phone number</label>
                                <input type="text" className="form-control" id="phone" placeholder="E.g. 07xxxxxxxx" value={formData.phone} onChange={handleInputChange} required />
                            </div>
                        </div>
                        <div className="row g-2 mt-4">
                            <h1 className="text-uppercase">Delivery address</h1>
                            <div className="col-md-12">
                                <label htmlFor="county" className="form-label">Select county</label>
                                <select className="form-select" id="county" value={formData.county} onChange={handleInputChange} required>
                                    <option value="" defaultValue=""></option>
                                    {counties.length > 0
                                        ? counties.map(county => (
                                            <option key={county.county_code} value={county.county_name}>{county.county_name}</option>
                                            ))
                                        : <option>Fetching counties...</option>}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="sub_county" className="form-label">Sub county</label>
                                <select className="form-select" id="sub_county" value={formData.sub_county} onChange={handleInputChange} required>
                                    <option value="" defaultValue=""></option>
                                    {subCounties.length > 0
                                        ? subCounties.map(subCounty => (
                                            <option key={subCounty.constituency_name} value={subCounty.constituency_name}>{subCounty.constituency_name}</option>
                                        ))
                                        : <option value="" defaultValue=""></option>}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="ward" className="form-label">Ward</label>
                                <select className="form-select" id="ward" value={formData.ward} onChange={handleInputChange} required>
                                    <option value="" defaultValue=""></option>
                                    {wards.length > 0
                                        ? wards.map(ward => (
                                            <option key={ward} value={ward}>{ward}</option>
                                        ))
                                        : <option>Fetching wards...</option>}
                                </select>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="street" className="form-label">Home Address</label>
                                <input type="text" className="form-control" id="street" placeholder="Street Address" value={formData.street} onChange={handleInputChange} required />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-dark my-3 mx-auto">Place order</button>
                        </div>
                    </form>
                </div>
                <div className="order-details mx-2 p-2">
                    <h1 className="text-uppercase">Order details</h1>
                    <div className="details d-flex flex-column mt-2 p-2">
                        {cartItems.map((item, index) => {
                            let { name, quantity } = item;
                            let product = products.find(p => p.name === name);

                            if (product) {
                                let { price, image } = product;
                                return (
                                    <div key={name} className="d-flex align-items-center">
                                        <img src={image} alt={name} className="product-image" />
                                        <div className="mx-5">
                                            <p>{name}</p>
                                            <p>Quantity: {quantity}</p>
                                            <p>Unit price: Kshs. {price}</p>
                                            <p>Total price: Kshs. {quantity * price}</p>
                                        </div>
                                    </div>
                                );
                            } else {
                                return <p key={name}>Product not found for {name}</p>;
                            }
                        })}
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Cart Subtotal</p>
                        <p><b>Kshs. {formData.amount}</b></p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                        <p>Total</p>
                        <p><b>Kshs. {formData.amount}</b></p>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <p><b>Payment terms</b></p>
                        <p><b>Cash on delivery (COD)</b></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Checkout;
