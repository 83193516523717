import '../CSS/Success.css'
import Logo from '../../assets/Images/logo.png'

const OrderSuccess= () =>
{
    return (
        <>
            <div class="main paragraph">
                <p>
                    <img src={Logo} alt="logo"/>
                </p>
                <p>Thank you &hearts; for
                shopping with Liteflux Enterprises</p> <p>Your
                order has been successfully placed. Kindly check your
                email for the order confirmation</p> <p>Your
                order is currently being processed. We will notify you
                when your order is ready for delivery.</p> 
                <a href="https://litefluxent.com/shop" class="btn" style={{backgroundColor: "#005477", color: "white"}}> Shop again 🚀</a> 
            </div>
        </>
    )   
}
export default OrderSuccess;