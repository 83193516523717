import React from 'react';
import '../CSS/PlaceholderCard.css'; // Make sure to create this CSS file for styling

const PlaceholderCard = () => (
    <div className="placeholder-card">
        <div className="placeholder-image"></div>
        <div className="placeholder-text title"></div>
        <div className="placeholder-text price"></div>
        <div className="placeholder-buttons">
            <div className="placeholder-button"></div>
            <div className="placeholder-button"></div>
        </div>
    </div>
);

export default PlaceholderCard;
