import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/CSS/Navbar.css'

import { ToastContainer} from 'react-toastify';
import {Routes, Route } from 'react-router-dom'

//Importing components
import Home from './Main Page/Home'
import Layout from './Shop/Layout';

function App() {
  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover={false} theme="colored"/> 
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/*" element={<Layout/>}></Route>
    </Routes>
    </>
  );
}

export default App;
