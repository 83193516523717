import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {toast} from 'react-toastify'
import Navbar from "../Shop/Components/Navbar";
import Shop from "./Pages/Shop";
import Item from "./Items/Item";
import Cart from "./Pages/Cart";
// import Account from "./Account/Account";

// import Products from './Data/Products.json'
import Checkout from "./Pages/Checkout";
import OrderSuccess from "./Components/OrderSuccess";

const Layout = () => 
{    
    //State that keeps track of the number of items in the cart
    const [cartCount, setCartCount]=useState(0)

    //State that stores the products from the backend
    const [products, setProducts]=useState([])

    //Fetch the products from the backend
    useEffect(()=>
    {
        fetch("https://products.litefluxent.com/products")
        .then(response => response.json())
        .then(data => setProducts(data)
        )
    },[])

    // State that keeps track of the quantity of items a user wants to add to cart
    const [quantity, setQuantity]=useState(1)

    //State that keeps track of the items in the local storage
    const [itemsInCart, setItemsInCart]=useState(JSON.parse(localStorage.getItem("shopping-cart")) || [])

    //Function to add new item to cart-> avoids repetition of code in the addToCart() method below
    const newCartItem= (name, quantity) =>
    {
        const newItem={name, quantity}
        const updatedCart=[...itemsInCart, newItem]
        setItemsInCart(updatedCart)
        localStorage.setItem("shopping-cart", JSON.stringify(updatedCart))
        toast.success("Item added to cart")
    }

    //Function to add items to cart
    const addToCart= name =>
        {
            if(itemsInCart)
            {
                //Checking if the cart is empty. If it is, add the item to cart
                if(itemsInCart.length === 0)
                {
                    newCartItem(name, quantity)
                }
    
                //If the cart is not empty, check if the item already exists in the cart
                else if(itemsInCart.length >= 1)
                {
                    let isItemInCart=false
    
                    itemsInCart.forEach(item => 
                    {
                        if(item.name === name)
                        {
                            isItemInCart=true
                        }
                        else
                        {
                            isItemInCart=false
                        }
                    });
    
                    isItemInCart ? toast.error("Item already added to cart") : newCartItem(name, quantity) 
    
                }
                
            }
        }
    
    
    //Updating the cart count once the itemsInCart state changes
    useEffect(()=>
    {
        setCartCount(itemsInCart.length)
    },[itemsInCart])
    return ( 
        <>
            <Navbar cartCount={cartCount}/>
            <Routes>
                <Route path="shop" element={<Shop products={products} setProducts={setProducts} addToCart={addToCart}/>}></Route>
                <Route path=":name" element={<Item products={products} quantity={quantity} setQuantity={setQuantity} addToCart={addToCart}/>}></Route>
                <Route path="cart" element={<Cart cartItems={itemsInCart} setItemsInCart={setItemsInCart} products={products}/>}></Route>
                <Route path="checkout" element={<Checkout cartItems={itemsInCart} products={products} setCartCount={setCartCount}/>}></Route>
                <Route path="confirmation" element={<OrderSuccess/>}></Route>
            </Routes>
        </>
     );
}
 
export default Layout;